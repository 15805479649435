<template>
  <div class="bg-[#FFFFFF]">
    <div
      class="w-100 bg-[#F8F8F8] border border-2 px-1 py-[1.2rem] rounded-t-xl font-semibold text-black"
    >
      Kesamaan Akun
    </div>
    <div class="p-1 border border-2 rounded-b-xl text-black h-[50rem] overflow-auto">
      <div class="border border-2 p-1 rounded-xl my-2">
        <div>Nama Pemilik Bank :</div>
        <div v-if="similarAccounts.same_bank_account_name !== null">
          <div
            v-for="data in similarAccounts.same_bank_account_name"
            :key="data.balance"
            class="my-2 border-b-2 border-dotted"
          >
            <div class="text-sm text-[#626262]">
              {{ data.email }}
            </div>
            <div class="d-flex my-1">
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/wallet.svg"
                alt="wallet"
              >
              <div
                class="text-2xl ml-1"
                :class="data.balance < 0 ? 'typeRed' : 'typeGreen'"
              >
                {{ IDR(data.balance) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center my-2 border-b-2 border-dotted"
        >
          <div class="my-1">
            (Belum ada kesamaan dengan akun lain)
          </div>
        </div>
      </div>
      <div class="border border-2 p-1 rounded-xl my-2">
        <div>Nomor Rekening Bank :</div>
        <div v-if="similarAccounts.same_bank_acccount_no !== null">
          <div
            v-for="data in similarAccounts.same_bank_acccount_no"
            :key="data.balance"
            class="my-2 border-b-2 border-dotted"
          >
            <div class="text-sm text-[#626262]">
              {{ data.email }}
            </div>
            <div class="d-flex my-1">
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/wallet.svg"
                alt="wallet"
              >
              <div
                class="text-2xl ml-1"
                :class="data.balance < 0 ? 'typeRed' : 'typeGreen'"
              >
                {{ IDR(data.balance) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center my-2 border-b-2 border-dotted"
        >
          <div class="my-1">
            (Belum ada kesamaan dengan akun lain)
          </div>
        </div>
      </div>
      <div class="border border-2 p-1 rounded-xl my-2">
        <div>Nomor HP :</div>
        <div v-if="similarAccounts.same_phone !== null">
          <div
            v-for="data in similarAccounts.same_phone"
            :key="data.balance"
            class="my-2 border-b-2 border-dotted"
          >
            <div class="text-sm text-[#626262]">
              {{ data.email }}
            </div>
            <div class="d-flex my-1">
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/wallet.svg"
                alt="wallet"
              >
              <div
                class="text-2xl ml-1"
                :class="data.balance < 0 ? 'typeRed' : 'typeGreen'"
              >
                {{ IDR(data.balance) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center my-2 border-b-2 border-dotted"
        >
          <div class="my-1">
            (Belum ada kesamaan dengan akun lain)
          </div>
        </div>
      </div>
      <div class="border border-2 p-1 rounded-xl my-2">
        <div>Nomor HP Bisnis :</div>
        <div v-if="similarAccounts.same_business_phone !== null">
          <div
            v-for="data in similarAccounts.same_business_phone"
            :key="data.balance"
            class="my-2 border-b-2 border-dotted"
          >
            <div class="text-sm text-[#626262]">
              {{ data.email }}
            </div>
            <div class="d-flex my-1">
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/wallet.svg"
                alt="wallet"
              >
              <div
                class="text-2xl ml-1"
                :class="data.balance < 0 ? 'typeRed' : 'typeGreen'"
              >
                {{ IDR(data.balance) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center my-2 border-b-2 border-dotted"
        >
          <div class="my-1">
            (Belum ada kesamaan dengan akun lain)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'

export default {
  props: {
    similarAccounts: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      TIME,
      IDR,
    }
  },
}
</script>

<style scoped lang="scss">
.typeGreen {
  color: #34a770;
  font-weight: 600;
}
.typeRed {
  color: #e31a1a;
  font-weight: 600;
}
</style>
