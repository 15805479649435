<template>
  <div class="bg-[#FFFFFF]">
    <div
      class="w-100 bg-[#F8F8F8] border border-2 text-black p-1 rounded-t-xl font-semibold"
    >
      Catatan Perubahan Email & Nomor Hp
    </div>
    <div class="border border-2 p-1 rounded-b-xl mb-2">
      <BRow>
        <BCol lg="6">
          <div class="border border-2 rounded-xl mb-2 p-1">
            <div
              class="text-black mb-2"
            >
              Perubahan Email :
            </div>
            <div
              v-if="logsChanged.email_log !== null"
              class="overflow-y-auto h-[6rem] text-black mt-[5px] overflow-auto"
            >
              <div
                v-for="(item, index) in logsChanged.email_log"
                :key="index"
              >
                <div v-if="index === 0">
                  <span class="p-[5px]">{{ index + 1 }}.</span>
                  <span class="ml-[5px]">{{ item.old_value }}</span>
                </div>
                <div>
                  <span class="p-[5px]">{{ index + 1 }}.</span>
                  <span class="mx-[5px]">{{ item.new_value }}</span>
                  <span class="mr-[5px]">-</span>
                  <span>{{ DAY_MONTH_YEAR(item.created_at) }} Pukul
                    {{ TIME(item.created_at) }} WIB</span>
                </div>
              </div>
            </div>
            <div
              v-else
              class="h-[3rem] text-black text-center"
            >
              Tidak Ada Perubahan
            </div>
          </div>
        </BCol>
        <BCol lg="6">
          <div class="border border-2 rounded-xl mb-2 p-1">
            <div
              class="text-black mb-2"
            >
              Perubahan Nomor HP :
            </div>
            <div
              v-if="logsChanged.phone_log !== null"
              class="overflow-y-auto h-[6rem] text-black mt-[5px] overflow-auto"
            >
              <div
                v-for="(item, index) in logsChanged.phone_log"
                :key="index"
              >
                <div v-if="index === 0">
                  <span class="p-[5px]">{{ index + 1 }}.</span>
                  <span class="ml-[5px]">{{ item.old_value }}</span>
                </div>
                <div>
                  <span class="p-[5px]">{{ index + 1 }}.</span>
                  <span class="mx-[5px]">{{ item.new_value }}</span>
                  <span class="mr-[5px]">-</span>
                  <span>{{ DAY_MONTH_YEAR(item.created_at) }} Pukul
                    {{ TIME(item.created_at) }} WIB</span>
                </div>
              </div>
            </div>
            <div
              v-else
              class="h-[3rem] text-black text-center"
            >
              Tidak Ada Perubahan
            </div>
          </div>
        </BCol>
      </BRow>
    </div>
    <div class="">
      <div class="w-100 bg-[#F8F8F8] border border-2 text-black p-1 rounded-t-xl font-semibold">
        Catatan Penambahan Nomor Rekening
      </div>
      <div class="border border-2 rounded-b-xl mb-2 p-1">
        <div
          v-if="logsChanged.rekening_log !== null"
          class="overflow-y-auto h-[6rem] text-black mt-[5px] overflow-auto"
        >
          <div
            v-for="(item, index) in logsChanged.rekening_log"
            :key="index"
          >
            <span class="p-[5px]">{{ index + 1 }}.</span>
            <span class="mr-[5px]">{{ item.new_value }}</span>
            <span class="mr-[5px]">-</span>
            <span>{{ DAY_MONTH_YEAR(item.created_at) }} Pukul
              {{ TIME(item.created_at) }} WIB</span>
          </div>
        </div>
        <div
          v-else
          class="h-[6rem] text-black text-center"
        >
          Tidak Ada Perubahan
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'

export default {
  props: {
    logsChanged: {
      type: Object,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      TIME,
    }
  },
}
</script>
