<template>
  <BModal
    id="modal-pin"
    ref="modal-pin"
    no-stacking
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    size="md"
    hide-header
  >
    <div
      class="text-center text-black"
    >
      <div class="pt-2">
        <div
          class="text-24-bold"
        >
          Masukan PIN
        </div>
        <br>
        <div
          class="text-14-normal mb-1"
        >
          Masukan PIN kamu
        </div>
        <div
          id="pin"
          class="flex flex-row justify-center text-center px-4"
        >
          <CodeInput
            :style="!showPin ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
            :loading="false"
            type="number"
            @change="onChangeConfirm"
          />
        </div>
        <div
          v-if="errorPin"
          class="text-danger font-bold mt-1"
        >PIN Tidak Sesuai</div>
        <div
          class="text-primary mt-2"
          type="button"
          @click="setShowPin()"
        >
          {{ !showPin ? 'Tampilkan' : 'Sembunyikan' }}
        </div>
        <br>
        <div class="my-1">
          <BButton
            variant="outline-primary"
            class="w-44"
            @click="closeModal"
          >
            Kembali
          </BButton>
          <BButton
            :variant="disableBtn ? 'secondary' : 'primary'"
            class="ml-1 w-44"
            :disabled="disableBtn"
            @click="onFinish"
          >
            <BSpinner
              v-if="isLoading"
              variant="light"
              small
            />
            Konfirmasi
          </BButton>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { axiosIns } from '@/libs/axios'
import CodeInput from 'vue-verification-code-input'

export default {
  components: { BButton, BSpinner, CodeInput },
  props: {
    modalValue: {
      type: Object,
      default: () => {},
    },
    getDetailWithdrawal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      disableBtn: true,
      pinDigits: '',
      showPin: false,
      errorPin: false,
    }
  },
  methods: {
    onChangeConfirm(value) {
      this.pinDigits = value
      this.errorPin = false
      if (this.pinDigits.length === 6) {
        this.disableBtn = false
      } else {
        this.disableBtn = true
      }
    },
    setShowPin() {
      this.showPin = !this.showPin
    },
    async onFinish() {
      this.isLoading = true
      this.disableBtn = true
      const pin = {
        pin: Number(this.pinDigits),
      }
      const endpoint = '/v1/user/admin/check-pin'
      await axiosIns
        .post(endpoint, pin)
        .then(response => {
          const { data } = response.data
          if (data.is_check) {
            this.topUp()
          } else {
            this.errorPin = true
            this.isLoading = false
            this.disableBtn = true
          }
        })
        .catch(() => {
          this.errorPin = true
          this.isLoading = false
          this.disableBtn = true
        })
    },
    async topUp() {
      let textToast = ''
      if (this.modalValue.type === 'TopupManual') {
        textToast = 'Berhasil menambahkan saldo user'
      } else if (this.modalValue.type === 'BalanceReduction') {
        textToast = 'Berhasil mengurangi saldo user'
      } else if (this.modalValue.type === 'TransferSaldo') {
        textToast = 'Berhasil mentransfer saldo user'
      }
      const body = {
        withdrawal_id: parseInt(this.modalValue.withdrawalId, 10),
        nominal: this.modalValue.nominal,
        notes: this.modalValue.notes,
        type_transaction: this.modalValue.type,
        email: this.modalValue.email,
      }
      const endpoint = '/v1/user/admin/manual-transaction'
      await axiosIns
        .post(endpoint, body)
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Berhasil',
              icon: 'CheckIcon',
              text: textToast,
              variant: 'success',
            },
          }, 2000)
          this.getDetailWithdrawal()
          this.closeModal()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Maaf Transaksi Anda Gagal',
              variant: 'danger',
            },
          }, 500)
          this.isLoading = false
          this.disableBtn = false
        })
    },
    closeModal() {
      this.isLoading = false
      this.disableBtn = true
      this.errorPin = false
      this.modalValue.notes = ''
      this.modalValue.email = ''
      this.modalValue.nominal = 0
      this.modalValue.emailRequired = false
      this.$nextTick(() => {
        this.$bvModal.hide('modal-pin')
      })
    },
  },
}
</script>
