<template>
  <b-tooltip
    :target="target"
    triggers="hover"
    placement="top"
  >
    <div class="font-semibold text-start mb-1">{{ title }}</div>
    <div class="text-start">{{ message }}</div>
  </b-tooltip>
</template>

<script>
export default {
  props: {
    target: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    message: {
      type: String,
      default: '',
      required: false,
    },
  },
}
</script>
