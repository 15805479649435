<template>
  <div class="bg-[#FFFFFF]">
    <div class="w-100 bg-[#F8F8F8] border border-2 p-1 rounded-t-xl d-flex justify-content-between text-black">
      <div class="font-semibold d-flex w-50">
        <span class="align-self-center">Ringkasan Info</span>
        <img
          v-if="problemRatio !== null"
          v-b-tooltip.hover="
            'Copy Rasio Problem, Rasio Retur, dan Problem Terbanyak'
          "
          class="cursor-pointer w-7 mx-1"
          src="https://storage.googleapis.com/komerce/assets/copy.svg"
          alt="copy"
          @click="toastCopy(problemRatio, mostProblem)"
        >
      </div>
      <div class="bg-[#E2E2E2] p-[5px] text-xs">
        Last Pickup
        <span class="font-semibold text-sm">
          {{ validateDate(pickupSummary.last_pickup) }}
        </span>
      </div>
    </div>
    <div class="p-1 border border-2 rounded-b-xl h-[50rem] text-black overflow-auto">
      <BRow
        v-if="problemRatio !== null"
        class="my-1"
      >
        <BCol
          md="6"
          class="mb-1"
        >
          <div class="border border-2 p-1 rounded-xl">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <span class="k-percentage-circle bg-[#FFF2E2] text-[#FBA63C] text-3xl p-[5px] rounded" />
                <span class="align-self-center mx-1">Rasio Problem</span>
              </div>
              <div
                class="text-black font-semibold align-self-start"
              >
                <img
                  id="rasio-problems"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                  class="cursor-pointer w-6"
                >
                <custom-tooltip
                  target="rasio-problems"
                  title="Rasio Problem"
                  message="Banyaknya tiket di status selain Batal (COD dan Non COD) dibandingkan dengan total paket status Dikirim + Diterima + Retur + Hilang + Rusak"
                />
              </div>
            </div>
            <div class="font-bold text-2xl mb-1 ml-4 d-flex d-lg-block d-xl-flex">
              {{
                problemRatio.problem_ratio
                  .percentage
              }}%
              <div class="text-white text-sm font-normal p-[5px] ml-lg-0 ml-1 ml-xl-1 bg-[#FBA63C] rounded-lg w-fit">
                (
                {{ problemRatio.problem_ratio.ratio[0] }}
                /
                {{ problemRatio.problem_ratio.ratio[1] }}
                )
              </div>
            </div>
          </div>
        </BCol>
        <BCol>
          <div class="border border-2 p-1 rounded-xl">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <span class="k-box bg-[#FFEDED] text-[#E31A1A] text-3xl p-[5px] rounded" />
                <span class="align-self-center mx-1">Rasio Retur</span>
              </div>
              <div
                class="text-black font-semibold align-self-start"
              >
                <img
                  id="rasio-retur"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                  class="cursor-pointer w-6"
                >
                <custom-tooltip
                  target="rasio-retur"
                  title="Rasio Retur"
                  message="Banyaknya orderan yang Retur (COD/Non COD tiket/tanpa tiket) dibandingkan dengan paket yang selesai (Diterima + Retur + Hilang + Rusak)"
                />
              </div>
            </div>
            <div class="font-bold text-2xl mb-1 ml-4 d-flex d-lg-block d-xl-flex">
              {{
                problemRatio.retur_ratio
                  .percentage
              }}%
              <div class="text-white text-sm font-normal p-[5px] bg-[#FBA63C] ml-lg-0 ml-1 ml-xl-1 bg-[#FBA63C] rounded-lg w-fit">
                (
                {{ problemRatio.retur_ratio.ratio[0] }}
                /
                {{ problemRatio.retur_ratio.ratio[1] }}
                )
              </div>
            </div>
          </div>
        </BCol>
      </BRow>
      <div class="my-1">
        <div class="w-100 bg-[#F8F8F8] border border-2 p-1 rounded-t-xl d-flex text-black">
          <div class="font-semibold">
            Problem Terbanyak
          </div>
        </div>
        <div
          class="border border-2 p-1 rounded-b-xl h-[8rem] overflow-auto"
        >
          <ul v-if="mostProblem !== null">
            <li
              v-for="(item, idx) in mostProblem"
              :key="idx"
            >
              {{ item.description }}
              <span
                style="color: #F95031"
              >
                {{ item.percentage }}% ({{
                  item.ratio[0]
                }}/{{ item.ratio[1] }})
              </span>
            </li>
          </ul>
          <div
            v-else
            class="text-center"
          >
            Tidak Ada Problem
          </div>
        </div>
      </div>
      <div class="border border-2 p-1 rounded-xl">
        <BRow class="my-2">
          <BCol cols="5">
            Nama Akun
          </BCol>
          <BCol
            cols="7"
            class="d-flex gap-[10px] text-black font-semibold"
          >
            <div class="d-flex">
              <span>: {{ partnerName }}</span>
              <img
                v-if="partnerVerified"
                class="mx-1"
                src="https://storage.googleapis.com/komerce/icon/verified.svg"
                alt="verification"
              >
            </div>
          </BCol>
        </BRow>
        <BRow class="my-2">
          <BCol cols="5">
            Nama Bisnis
          </BCol>
          <BCol
            cols="7"
            class="text-black font-semibold"
          >
            : {{ summaryInformation.brand_name !== '' ? summaryInformation.brand_name : '-' }}
          </BCol>
        </BRow>
        <BRow class="my-2">
          <BCol cols="5">
            Email
          </BCol>
          <BCol
            cols="7"
            class="text-black font-semibold d-flex"
          >
            : {{ summaryInformation.partner_email }}
            <span
              v-b-tooltip.hover.top="`${partnerNotes}`"
              class="k-note-21 bg-[#FCD4BE] text-primary align-self-end rounded-full p-[3px] cursor-pointer ml-1"
              @click="$bvModal.show('email-notes')"
            />
          </BCol>
        </BRow>
        <BRow class="my-2">
          <BCol cols="5">
            Saldo Terbaru
          </BCol>
          <BCol
            cols="7"
            class="text-black font-semibold"
          >
            : <span class="text-primary">{{ IDR(summaryInformation.recent_balance) }}</span>
          </BCol>
        </BRow>
        <BRow class="my-2">
          <BCol cols="5">
            Tanggal Bergabung
          </BCol>
          <BCol
            cols="7"
            class="text-black font-semibold"
          >
            : {{ DAY_MONTH_YEAR(summaryInformation.join_date) }}
          </BCol>
        </BRow>
        <BRow class="my-2">
          <BCol cols="5">
            First Pickup
          </BCol>
          <BCol
            cols="7"
            class="text-black font-semibold"
          >
            : {{ validateDate(pickupSummary.first_pickup) }}
          </BCol>
        </BRow>
      </div>
    </div>
    <modal-notes-email
      :email="summaryInformation.partner_email"
      :partner-notes="partnerNotes"
      :partner-id="partnerId"
      :date-updated="dateUpdated"
      @success-update-notes="$emit('refresh-withdrawal')"
    />
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import customTooltip from './customTooltip.vue'
import ModalNotesEmail from './ModalNotesEmail.vue'

export default {
  components: {
    customTooltip,
    ModalNotesEmail,
  },
  props: {
    summaryInformation: {
      type: Object,
      default: () => null,
      required: true,
    },
    problemRatio: {
      type: Object,
      default: null,
      required: false,
    },
    mostProblem: {
      type: Array,
      default: null,
      required: false,
    },
    pickupSummary: {
      type: Object,
      default: () => {},
      required: true,
    },
    partnerName: {
      type: String,
      required: true,
    },
    partnerNotes: {
      type: String,
      required: true,
    },
    partnerVerified: {
      type: Boolean,
      required: true,
    },
    dateUpdated: {
      type: String,
      required: true,
    },
    partnerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      TIME,
      IDR,
    }
  },
  methods: {
    toastCopy(problemRatio, mostProblem) {
      const copyValue = [
        `1. Rasio Problem : ${problemRatio.problem_ratio.percentage}%`,
        `2. Rasio Retur : ${problemRatio.retur_ratio.percentage}%`,
        '3. Problem Terbanyak',
      ]
      if (mostProblem !== null) {
        mostProblem.forEach(item => {
          copyValue.push(`- ${item.description} ${item.percentage}% (${item.ratio[0]}/${item.ratio[1]})`)
        })
      }
      navigator.clipboard.writeText(copyValue.join('\n'))
      this.$toast_success({ message: 'Rasio Problem, Rasio Retur, dan Problem Terbanyak Berhasil disalin' })
    },
    validateDate(date) {
      let result
      if (date !== undefined && date.startsWith('000')) {
        result = '-'
      } else {
        result = DAY_MONTH_YEAR(date)
      }
      return result
    },
  },
}
</script>

<style scoped lang="scss">
.logo-container {
  border: 1px solid #e2e2e2;
  height: 32px;
  width: 32px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
