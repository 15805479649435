<template>
  <div class="text-center">
    <div class="flex justify-center">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/warning.svg"
      >
    </div>
    <div class="pt-3">
      <span
        class="text-24-bold"
        style="color: #222222"
      >
        Apakah kamu yakin ingin mentransfer nominal penarikan saldo?
      </span>
      <br>
      <br>
      <div class="my-1">
        <BButton
          variant="primary"
          class="mr-1"
          :disabled="disableBtn"
          @click="onFinish"
        >
          <BSpinner
            v-if="isLoading"
            variant="light"
            small
          />
          Yakin
        </BButton>
        <BButton
          variant="outline-primary"
          style="border-color: unset"
          @click="closeModal"
        >
          Tidak
        </BButton>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { newAxiosIns } from '@/libs/axios'

export default {
  components: { BButton, BSpinner },
  props: {
    getDetailWithdrawal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      idWithdrawal: this.$route.params.slug,
      disableBtn: false,
    }
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async onFinish() {
      this.isLoading = true
      this.disableBtn = true
      const endpoint = `/auth/api/v2/admin/withdrawal/update/${this.idWithdrawal}`
      const formData = new FormData()
      formData.append('withdrawal_id', this.idWithdrawal)
      await newAxiosIns
        .put(endpoint, formData)
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Transfer Saldo Berhasil!',
              variant: 'success',
            },
          }, 2000)
          this.closeModal()
          this.getDetailWithdrawal()
          this.isLoading = true
          this.disableBtn = true
        })
        // eslint-disable-next-line consistent-return
        .catch(err => {
          this.isLoading = false
          this.disableBtn = true

          if (err.response.data.meta.message === 'insufficient kmpoin balance to perform this transaction') {
            return this.$bvModal.show('modal-insufficient-balance')
          }

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-transfer-now')
      })
    },
  },
}
</script>
