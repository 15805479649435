<template>
  <div class="bg-[#FFFFFF]">
    <div class="w-100 bg-[#F8F8F8] border border-2 text-black p-1 rounded-t-xl font-semibold">
      Analisa Saldo User
    </div>
    <div class="p-1 border border-2 rounded-b-xl h-[33rem] overflow-auto text-black">
      <div class="border border-2 p-1 rounded-xl h-[8.2rem]">
        <BRow>
          <BCol
            cols="10"
          >
            <div class="d-flex">
              <img
                class="w-10 mr-1"
                src="https://storage.googleapis.com/komerce/icon/dollar-out-blue.svg"
                alt="dollar-out"
              >
              <span class="align-self-center text-sm mb-1">Saldo Ideal</span>
            </div>
            <div class="font-semibold text-2xl ml-4">
              {{ IDR(balanceAnalitycs.ideal_balance) }}
            </div>
          </BCol>
          <BCol
            class="text-black font-semibold align-self-start "
          >
            <img
              id="saldo-ideal"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              alt="Komerce"
              class="cursor-pointer float-right"
            >
            <custom-tooltip
              target="saldo-ideal"
              title="Saldo Ideal"
              message="Batas bawah saldo yang harus dimiliki oleh seller. || Rumus = ((Ongkir Retur ketika sampai + Resiko Ongkir Menjadi Retur) - Potensi Pendapatan Order Dikirim)"
            />
          </BCol>
        </BRow>
      </div>
      <BRow>
        <BCol
          class="my-1"
          md="6"
        >
          <div class="border border-2 p-1 rounded-xl h-[8.2rem]">
            <div class="d-flex justify-content-between">
              <div
                class=""
              >
                <div class="d-flex">
                  <span class="k-box-add text-[#10673E] bg-[#DCF3EB] align-self-center text-3xl mr-1 p-[5px] rounded" />
                  <span class="align-self-center text-sm mb-1">Potensi Pendapatan Order Dikirim</span>
                </div>
                <div class="font-semibold text-xl ml-4 ml-lg-0 ml-xl-4">
                  {{ IDR(balanceAnalitycs.income_order_potential) }}
                </div>
              </div>
              <div
                class="text-black font-semibold align-self-start"
              >
                <img
                  id="saldo-order-dikirim"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                  class="cursor-pointer"
                >
                <custom-tooltip
                  target="saldo-order-dikirim"
                  title="Potensi Pendapatan Order Dikirim"
                  message="Total potensi pendapatan dari orderan yang berada di status 'Dikirim' lebih dari 1 hari tanpa terkena kendala / Ticketing"
                />
              </div>
            </div>
          </div>
        </BCol>
        <BCol
          class="my-1"
          md="6"
        >
          <div class="border border-2 p-1 rounded-xl h-[8.2rem]">
            <div class="d-flex justify-content-between">
              <div>
                <div class="d-flex">
                  <span class="k-box-time text-[#FBA63C] bg-[#FFF2E2] align-self-center text-3xl mr-1 p-[5px] rounded" />
                  <span class="align-self-center text-sm mb-1">Potensi Ongkir Retur</span>
                </div>
                <div class="font-semibold text-xl ml-4 ml-lg-0 ml-xl-4">
                  {{ IDR(balanceAnalitycs.ongkir_retur_potential) }}
                </div>
              </div>
              <div
                class="text-black font-semibold align-self-start"
              >
                <img
                  id="saldo-potensi-retur"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                  class="cursor-pointer"
                >
                <custom-tooltip
                  target="saldo-potensi-retur"
                  title="Potensi Ongkir Retur"
                  message="Total potensi dari orderan yang berada di status 'Dikirim' dan terkena kendala (Ticket)"
                />
              </div>
            </div>
          </div>
        </BCol>
      </BRow>
      <BRow>
        <BCol
          class="my-1"
          md="6"
        >
          <div class="border border-2 p-1 rounded-xl h-[8.2rem]">
            <div class="d-flex justify-content-between">
              <div>
                <div class="d-flex">
                  <span class="k-box-remove text-[#E31A1A] bg-[#FFEDED] align-self-center text-3xl mr-1 p-[5px] rounded" />
                  <span class="align-self-center text-sm mb-1">Ongkir Retur Ketika Sampai</span>
                </div>
                <div class="font-semibold text-xl ml-4 ml-lg-0 ml-xl-4">
                  {{ IDR(balanceAnalitycs.ongkir_retur_on_finished) }}
                </div>
              </div>
              <div
                class="text-black font-semibold align-self-start"
              >
                <img
                  id="saldo-retur"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                  class="cursor-pointer"
                >
                <custom-tooltip
                  target="saldo-retur"
                  title="Ongkir Retur Ketika Sampai"
                  message="Total ongkir orderan berstatus 'Retur Dalam Perjalanan' yang belum mengurangi saldo (karena barang belum sampai gudang pengirim)"
                />
              </div>
            </div>
          </div>
        </BCol>
        <BCol
          class="my-1"
          md="6"
        >
          <div class="border border-2 p-1 rounded-xl h-[8.2rem]">
            <div class="d-flex justify-content-between">
              <div>
                <div class="d-flex">
                  <span class="k-box-search text-[#333333] bg-[#F4F4F4] align-self-center text-3xl mr-1 p-[5px] rounded" />
                  <span class="align-self-center text-sm mb-1">Resiko Ongkir Jadi Retur</span>
                </div>
                <div class="font-semibold text-xl ml-4 ml-lg-0 ml-xl-4">
                  {{ IDR(balanceAnalitycs.ongkir_risk_become_retur) }}
                </div>
              </div>
              <div
                class="text-black font-semibold align-self-start"
              >
                <img
                  id="saldo-resiko-retur"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                  class="cursor-pointer"
                >
                <custom-tooltip
                  target="saldo-resiko-retur"
                  title="Resiko Ongkir Jadi Retur"
                  message="Total ongkir dari perhitungan resiko orderan yang berpotensi berubah menjadi Retur (potensi dari order berstatus 'Dikirim' dan terkena kendala dikalikan rasio retur akun user) || (Rumus = Potensi Ongkir Retur x Rasio Retur User (%))"
                />
              </div>
            </div>
          </div>
        </BCol>
      </BRow>
    </div>
  </div>
</template>

<script>
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import customTooltip from './customTooltip.vue'

export default {
  components: {
    customTooltip,
  },
  props: {
    balanceAnalitycs: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      TIME,
      IDR,
    }
  },
}
</script>

<style scoped lang="scss">
.logo-container {
  border: 1px solid #e2e2e2;
  height: 32px;
  width: 32px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
