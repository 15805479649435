<template>
  <BModal
    id="email-notes"
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    centered
    @shown="notes = partnerNotes"
  >
    <div class="text-center my-3">
      <h3 class="font-semibold">
        Catatan
      </h3>
      <h6>{{ email }}</h6>
    </div>
    <div class="my-2">
      <BFormTextarea
        v-model="notes"
        placeholder="Masukan catatan disini"
        rows="6"
        :autofocus="true"
        :formatter="maxLength"
      />
      <small
        v-if="notes.length < 10 && notes.length >= 1"
        class="text-danger mr-auto"
      >Minimal 10 Karakter</small>
      <small class="float-end mb-2">{{ notes.length }}/250</small>
    </div>
    <div class="d-flex w-full bg-[#FFF2E2] text-[#AF6A13] my-2 p-1">
      <div class="mr-1">
        Last Update :
      </div>
      <div>
        {{ dateUpdated !== '' ? `${TIME(dateUpdated)} ${DATE_NUMBER_SLASH(dateUpdated)}` : '-' }}
      </div>
    </div>
    <div class="d-flex my-1 gap-10">
      <BButton
        variant="outline-primary"
        block
        @click="$bvModal.hide('email-notes')"
      >
        Batal
      </BButton>
      <BButton
        variant="primary"
        class="mt-0"
        block
        :disabled="notes.length < 10 || notes === partnerNotes"
        @click="updateNotes()"
      >
        Simpan
      </BButton>
    </div>
  </BModal>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { DATE_NUMBER_SLASH, TIME } from '@/libs/filterDate'

export default {
  props: {
    email: {
      type: String,
      required: true,
    },
    partnerNotes: {
      type: String,
      required: true,
    },
    dateUpdated: {
      type: String,
      required: true,
    },
    partnerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      DATE_NUMBER_SLASH,
      TIME,
      notes: '',
    }
  },
  methods: {
    maxLength(e) {
      return String(e).substring(0, 250)
    },
    updateNotes() {
      const url = `/auth/api/v1/admin/withdrawal/update-notes/${this.partnerId}`
      const body = {
        notes: this.notes,
      }
      newAxiosIns
        .put(url, body)
        .then(res => {
          this.$toast_success({ message: 'Catatan berhasil disimpan' })
          this.$emit('success-update-notes')
          this.$bvModal.hide('email-notes')
        })
        .catch(err => {
          this.$toast_success({ message: 'Catatan gagal disimpan' })
        })
    },
  },
}
</script>
