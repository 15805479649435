<template>
  <div>
    <div class="mb-1 text-black text-semibold text-xl">
      Buat Catatan Penarikan
    </div>
    <form
      ref="form-reject"
      @submit.stop.prevent="onFinish"
    >
      <BFormGroup
        label=""
        label-for="catatan-penarikan-textarea"
        invalid-feedback="Silahkan isi catatan"
        :state="notesReject"
      >
        <BFormTextarea
          v-model="isNotesReject"
          debounce="300"
          placeholder="Berikan catatan kamu untuk partner dalam penarikan"
          rows="8"
          :state="notesReject"
          required
          :formatter="validMaxLength"
        />
        <BRow class="justify-content-end">
          <small class="mr-1 mt-50">
            {{ isNotesReject.length }}/250
          </small>
        </BRow>
      </BFormGroup>
      <div class="w-100 flex justify-end">
        <BButton
          variant="outline-primary"
          class="mr-1 text-center"
          @click="closeModal"
        >
          Batal
        </BButton>
        <BButton
          variant="primary"
          class="text-center"
          :disabled="checkValidityDisabled() || disabled"
          @click="onFinish"
        >
          <BSpinner
            v-if="isLoading"
            variant="light"
            small
          />
          Selesai
        </BButton>
      </div>
    </form>
  </div>
</template>

<script>
import { BFormTextarea, BFormGroup, BButton } from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komshipAxiosIns } from '@/libs/axios'

export default {
  components: {
    BFormTextarea,
    BFormGroup,
    BButton,
  },
  props: {
    getDetailWithdrawal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      notesReject: null,
      isNotesReject: '',
      idWithdrawal: this.$route.params.slug,
      isLoading: false,
      disabled: false,
    }
  },
  methods: {
    async onFinish() {
      this.disabled = true
      if (!this.checkFormValidityReview()) {
        return
      }
      this.isLoading = true
      const endpoint = `/v1/admin/withdrawal/update/${this.idWithdrawal}`
      const formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('status', 'rejected')
      formData.append('notes', this.isNotesReject)
      await komshipAxiosIns
        .post(endpoint, formData)
        .then(() => {
          this.disabled = true
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Withdrawal Berhasil DiTolak',
              variant: 'success',
            },
          }, 2000)
          this.closeModal()
          this.getDetailWithdrawal()
          this.isLoading = false
        })
        .catch(err => {
          this.disabled = true
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.isLoading = false
        })
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-reject')
      })
    },
    checkFormValidityReview() {
      const valid = this.$refs['form-reject'].checkValidity()
      this.notesReject = valid
      return valid
    },
    validMaxLength(e) {
      return String(e).substring(0, 250)
    },
    checkValidityDisabled() {
      if (this.isNotesReject.length === 0) {
        return true
      }
      return false
    },
  },
}
</script>
